<template>
  <div>
    <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="queryTitle"
    center
    destroy-on-close
    width="1100px"
    custom-class="add-new-note"
    top="0"
    append-to-body
    >
      <el-form ref="form" :model="queryForm" label-width="100px" label-position="left" :rules="queryFormRules">
        <el-row class="query-form-heard">
          <el-col :span="12">
            <el-form-item  label="票据单号" prop="receiptNumber">
              <el-input v-model.trim="queryForm.receiptNumber"  clearable 
              :disabled="operationalStatus === 2" maxlength="50"/>
            </el-form-item>
          </el-col>
          <el-col v-if="operationalStatus" :span="12">
            <el-form-item  label="付款方" prop="payer">
              <WewooRemoteSelect
                v-model="queryForm.payer"
                :api="TicketManagementInteractor.payerListPageApi"
                :debounceTime="800"
                :maxlength="90"
                allowCreate
                type="textarea"
                deleteScrollbar
                :autosize="{ minRows: 2, maxRows: 10}"
                class="wewoo-remote-select"
              />
              <!-- <el-input v-model.trim="queryForm.payer" clearable :disabled="operationalStatus === 2" maxlength="90" /> -->
            </el-form-item>
          </el-col>
          <el-col class="search-btn" :span="operationalStatus ? 24 : 12" v-if="operationalStatus !== 2 && operationalStatus !== 4">
              <el-button type="primary" @click="queryTicketFn" size="medium" :loading="queryTicketLoading">查询是否已存在该票据</el-button>
          </el-col>
        <template v-if="operationalStatus">
            <el-col :span="12">
              <el-form-item  label="收款方式" prop="paymentType" class="pl11">
                <el-select
                  v-model="queryForm.paymentType"
                  placeholder="请选择"
                  class="w100"
                  v-if="operationalStatus != 2"
                >
                  <el-option
                    v-for="item in paymentListOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span v-else>{{ queryForm.paymentType === 0 ?'支票':'电汇'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="票据币种" prop="receiptCurrency">
                <el-select
                  v-model="queryForm.receiptCurrency"
                  placeholder="请选择"
                  class="w100"
                  v-if="operationalStatus != 2"
                >
                  <el-option
                    v-for="item in billCurrencyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span v-else>{{ queryForm.receiptCurrency === 0 ? '美元' : '人民币' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="票据金额" prop="receiptAmount">
                <el-input v-model="queryForm.receiptAmount" type="number" clearable  maxlength="10"
                 oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                 v-if="operationalStatus != 2" @blur="formatReceiptAmount" />
                <span v-else>{{ addThousandSeparator(queryForm.receiptAmount) }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="票据余额" class="pl11">
                <span>{{ addThousandSeparator(queryForm.verificationBalance) }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="出票银行" prop="receiptBank" class="pl11">
                <el-input v-model="queryForm.receiptBank"  clearable v-if="operationalStatus != 2" maxlength="50" />  
                <span v-else>{{ queryForm.receiptBank }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="备注" prop="remark" class="pl11">
                <el-input v-model="queryForm.remark"  clearable  v-if="operationalStatus != 2" maxlength="50" />
                <span v-else>{{ queryForm.remark }}</span>
              </el-form-item>
            </el-col>
        </template>
        </el-row>
      </el-form>
      <h5 class="total-amount" v-if="operationalStatus">核销金额合计： $ {{ addThousandSeparator(totalAmount) }}</h5>
      <ticketTable ref="ticketTable" :tableForm="tableForm" v-if="operationalStatus" @deleteRowFn="deleteRowFn"></ticketTable>
      <filePanel v-if="operationalStatus" :noteAttachment.sync="noteAttachment" :emailScreenshot.sync="emailScreenshot"></filePanel>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFn" v-if="operationalStatus" :loading="confirmFnLoading">确 认</el-button>
      </span>
    </el-dialog>
    <queryDialog :queryDialogVisible.sync="queryDialogVisible" 
     :queryDialgData="queryDialgData" @handleQuery="handleQuery"></queryDialog>
  </div>
</template>
<script>
import { addThousandSeparator,decimal } from 'kits'
import { paymentListOptions ,billCurrencyOptions} from '../utils'
import queryDialog from './query-dialog'
import ticketTable from './ticket-table'
import filePanel from './file-panel.vue';
import WewooRemoteSelect from 'wewoo-remote-select'
import { TicketManagementInteractor } from '@/core'
export default {
  name: 'AddNewNote',
  props: {
    // 是否显示弹窗
    visibleAdd: {
      type: Boolean
    },
    // 是否是重新提交
    financeReceiptId:{
      type: String,
      default:''
    },
    // 订单列表勾选数据
    defaultData:{
      type:Array,
      default:()=>[]
    },
    //来源 0 票据管理 1订单管理 2 重新提交
    source:{
      type:Number,
      default:0
    }
  },
  components: {
    queryDialog,
    ticketTable,
    filePanel,
    WewooRemoteSelect
  },
  data() {
    return {
      TicketManagementInteractor,
      queryTicketLoading:false,//查询按钮loading
      confirmFnLoading:false,//确认按钮loading
      totalAmount:0,//核销金额合计
      queryForm:{
        payer:'',//付款方
        receiptNumber:'',//票据单号
        paymentType:0,//收款方式
        receiptCurrency:0,//票据币种
        receiptAmount:'',//票据金额
        verificationBalance:0,//票据余额
        receiptBank:'',//出票银行
        remark:'',//备注
      },
      paymentListOptions,
      billCurrencyOptions,
      queryFormRules:{
        payer: [
          { required: true, message: '请输入付款方', trigger: 'blur' }
        ],
        receiptNumber: [
          { required: true, message: '请输入票据单号', trigger: 'blur' },
          { 
            validator: (rule, value, callback) => {
              const isNumber = /^[a-zA-Z0-9]+$/.test(value);
              if (!isNumber) {
                callback(new Error('票据单号必须是数字或字母'));
              } else {
                callback();
              }
            }, 
            trigger: 'blur' 
          }
        ],
        receiptCurrency: [
          { required: true, message: '请选择票据币种', trigger: 'change' }
        ],
        receiptAmount: [
          { required: true, message: '请输入票据金额', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (value <= 0) {
              callback(new Error('票据金额必须大于0'));
            } else {
              callback();
            }
          }, trigger: 'blur' }
        ],
      },
      queryDialgData: [], // 票据单号弹窗数据
      operationalStatus:0,//查询操作状态0 未查询 1新增票据 2新增核销 4重新提交
      queryDialogVisible: false,//查询弹窗是否显示
      tableForm: {
        ticketTableData: []
      },//票据列表数据
      noteAttachment:[],//票据附件
      emailScreenshot:[],//邮件截图
      businessID:'',//业务ID
      delVerifyIds:[],//删除核销行id
    }
  },
  created() { 
    if(this.financeReceiptId){
      this.getFinanceReceiptData()
    }
  },
  watch: {
    'tableForm.ticketTableData': {
      handler(newValue) {
        this.totalAmount = newValue.reduce((acc, cur) => decimal.add(acc , Number(cur.writeOffAmount)), 0);
        this.queryForm.verificationBalance = this.queryForm.receiptAmount - this.totalAmount
      },
      deep: true // 深度监听，监测内部对象变化
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visibleAdd;
      },
      set(val) {
        this.$emit('update:visibleAdd', val);
      }
    },
    queryTitle(){
      const titleObj = {
        0: '新增票据',
        1: '新增票据',
        2: '新增核销',
        4: '重新提交票据'
      }
      return titleObj[this.operationalStatus]
    }
  },
  methods: {
    // 重新提交信息
   async getFinanceReceiptData(){
      const res = await TicketManagementInteractor.reDoDetailApi(this.financeReceiptId)
      if(res?.code === '000000'){
        const data = res?.data ?? {};
        for(let key in this.queryForm){
          this.queryForm[key] = data[key] ?? '';
        }
        this.noteAttachment = data?.files?.filter(item=>item.bizType === 0) ?? [];
        this.emailScreenshot = data?.files?.filter(item=>item.bizType === 1) ?? [];
        this.tableForm.ticketTableData = data?.verifies ?? [];
        this.operationalStatus = 4
      }  
        
    },
     // 销售单号删除行
    deleteRowFn(id) {
      this.delVerifyIds.push(id)
    },
    // 票据金额失去焦点计算票据余额
    formatReceiptAmount(){
      this.queryForm.verificationBalance = this.queryForm.receiptAmount - this.totalAmount
    },
    // 校验付款方和票据单号是否必填了
    checkPayer(){
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField('payer', (errorMessage) => {
          if(!errorMessage){
            resolve(true)
          }else{
            resolve(false)
          }
        })
      })
    },
    checkReceiptNumber(){
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField('receiptNumber', (errorMessage) => {
          if(!errorMessage){
            resolve(true)
          }else{
            resolve(false)
          }
        })
      })
    },

    //  查询是否已存在该票据
    async queryTicketFn(){
      const bool = await this.checkReceiptNumber()
      if (!bool) return
      this.queryTicketLoading = true
      const res = await TicketManagementInteractor.getFinanceReceiptListByNumberApi(this.queryForm.receiptNumber)
      this.queryTicketLoading = false
      if(res?.code === "000000") {
        this.queryDialgData = res?.data ?? []
        this.queryDialogVisible = true
      }
    },
    //  点击确认
    confirmFn(){
      this.$refs.form.validate(async(valid) => {
        if (valid) {
         let bol =   await this.$refs.ticketTable.checkTableForm()
         if(!bol)return;
         if(!this.tableForm?.ticketTableData?.length){
          this.$message.warning('票据至少有一个核销行')
          return;
         }
         if(!this.noteAttachment.length){
          this.$message.warning('请上传票据附件')
          return;
         }
         //校验票据余额不能为负数
         if(this.queryForm.verificationBalance < 0){
          this.$message.warning('票据余额不能为负数')
          return;
         }
         this.confirmFnLoading = true;
         const params = {
            ...this.queryForm
         }
         params.verifies = this.tableForm.ticketTableData;
         params.files = this.noteAttachment.concat(this.emailScreenshot);
         params.source = this.source;
         params.delVerifyIds = this.delVerifyIds;
         let res = {};
         if(this.operationalStatus === 2){
          params.id = this.businessID;
          res = await TicketManagementInteractor.verifyApi(params)
         }else{
          res = await TicketManagementInteractor.insertFinanceReceiptApi(params)
         }
        if(res?.code === "000000"){
            this.$message.success('操作成功')
            this.dialogVisible = false;
            this.$emit('addSuccessFn')
          }
          this.confirmFnLoading = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 查询弹窗点击确认
   async handleQuery(queryWriteOffData){
      // 新增再次查询清空数据
      if(this.operationalStatus === 1){
        this.queryForm.paymentType = 0;
        this.queryForm.receiptCurrency = 0;
        this.queryForm.receiptAmount = '';
        this.queryForm.verificationBalance = '';
        this.queryForm.receiptBank = '';
        this.queryForm.remark = '';
        this.tableForm.ticketTableData = [];
        this.noteAttachment = [];
        this.emailScreenshot = [];
      }
      // 查询到的核销数据
      if(Object.values(queryWriteOffData).length){
        this.queryForm.payer = queryWriteOffData.payer
        this.queryForm.paymentType = queryWriteOffData.paymentType;
        this.queryForm.receiptCurrency = queryWriteOffData.receiptCurrency;
        this.queryForm.receiptAmount = queryWriteOffData.receiptAmount;
        this.queryForm.verificationBalance = queryWriteOffData.verificationBalance;
        this.queryForm.receiptBank = queryWriteOffData.receiptBank;
        this.queryForm.remark = queryWriteOffData.remark;
        await this.getTableFormData([queryWriteOffData.id])

      }
      // 订单勾选的订单数据
      if(this.operationalStatus !== 1)this.getTableFormDataByOrder()
      // receiptNumber有值说明是新增核销，没值说明是新增票据
      this.operationalStatus = queryWriteOffData.receiptNumber ? 2 : 1
      this.queryDialogVisible = false;
    },
    // 获取订单勾选数据 
    async getTableFormDataByOrder(){
      if(this.defaultData?.length){
        const orderCodes = this.defaultData?.join(',');
        const res = await TicketManagementInteractor.findOrderAmountInfoByOrderCodeListApi({ orderCodes })
        if(res?.code === '000000'){
          const dataValue = res?.data?.reduce((previousValue, currentValue) => {
            let obj = {}
            obj.businessNameCn = currentValue?.businessName;
            obj.orderTotalAmount = currentValue?.amountReceivable;
            obj.collectionAmount = currentValue?.amountReceived;
            obj.unpaidAmount = currentValue?.outstandingAmount; 
            obj.businessId = currentValue?.businessId;
            obj.id = '';
            obj.financeReceiptId ='';
            obj.orderCode =currentValue?.orderCode;
            obj.writeOffAmount ='';
            obj.refundReceiptNumber ='';
            obj.financeStatus =null;
            obj.remark ='';
            previousValue.push(obj)
            return previousValue
          },[])
          this.tableForm.ticketTableData = [...this.tableForm.ticketTableData,...dataValue];
        }
      }
    },
    // 获取核销票据表格数据
    async getTableFormData(ids){
      const res = await TicketManagementInteractor.batchDetailWithOptApi(ids)
      if(res?.code === '000000'){
        const data = res?.data?.[0] ?? [];
        let writeOffData = data?.verifies ?? [];
        this.tableForm.ticketTableData = [...writeOffData,...this.tableForm.ticketTableData];
        this.totalAmount = data?.writeOffAmount ?? 0;
        this.noteAttachment = data?.files?.filter(item=>item.bizType === 0) ?? [];
        this.emailScreenshot = data?.files?.filter(item=>item.bizType === 1) ?? [];
        this.businessID = data?.id ?? '';
      }
   },
    // 处理千分位
    addThousandSeparator(val) {    
      return addThousandSeparator(val,true)    
    },
  } 
}   

</script>
<style lang="scss">
  .wewoo-remote-select .el-autocomplete-suggestion {
    width: auto !important;
    min-width: 100px;
    text-align: left;
  }
  .add-new-note{
    position: absolute !important;
    left: 50% !important;
    top: 0 !important;
    bottom: 0 !important;
    margin-left: -550px !important;
    margin-bottom: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    .el-dialog__header{
      padding: 10px 0 20px 10px !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }
    .el-dialog__body{
      flex: 1 !important;
      overflow-y: auto !important;
      padding: 0 20px !important;
      .query-form-heard{
        padding: 0 !important;
        .el-col{
          padding: 0 30px !important;
          &.search-btn{
            text-align: right !important;
            margin-bottom: 20px !important;
          }
        }
      }
    }
    .pl11{
      .el-form-item__label{
        padding-left: 11px !important;
      }
    }
    .total-amount{
        font-size: 16px;
        color: #000;
        margin-bottom: 20px;
     }
  }
</style>