<template>
  <el-dialog
    :title="
      queryDialgData.length
        ? '票据单号在系统中已存在'
        : '票据单号在系统中不存在'
    "
    :visible.sync="dialogVisible"
    :width="queryDialgData.length ? '800px' : '428px'"
    custom-class="query-dialog"
  >
    <div v-if="queryDialgData.length">
      票据单号：{{
        queryDialgData[0].receiptNumber
      }}，在系统中已存在如下票据，请核查后决定新增该票据或新增核销（将该PI添加到已有的票据中）。
      <el-table :data="queryDialgData" border class="mt20" max-height="360">
        <el-table-column prop="receiptCode" label="票据流水号" align="center" />
        <el-table-column prop="payer" label="付款方" align="center" />
        <el-table-column label="状态" align="center">
          <template slot-scope="{ row }">
            {{ row.receiptStatus | receiptStatusName }}
          </template>
        </el-table-column>
        <el-table-column prop="receiptAmount" label="票据金额" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <span
              v-if="[0, 1].includes(Number(row.receiptStatus))"
              class="page-link"
              @click="handleQuery(row)"
            >
              新增核销
            </span>
            <span v-else class="page-link" @click="handleDetail(row)">
              查看详情
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>请手工新增该票据</div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleQuery()" size="medium">
        <!-- {{ queryDialogIsExist ?'新增核销':'新增票据' }} -->
        新增票据
      </el-button>
      <el-button @click="dialogVisible = false" size="medium">
        取消新增
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    name: 'QueryDialog',
    props: {
      // 控制弹窗显示隐藏
      queryDialogVisible: {
        type: Boolean,
        default: false,
      },
      queryDialgData: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      // 控制弹窗显示隐藏
      dialogVisible: {
        get() {
          return this.queryDialogVisible
        },
        set(val) {
          this.$emit('update:queryDialogVisible', val)
        },
      },
    },
    filters: {
      receiptStatusName(val) {
        const map = { 0: '待确认', 1: '已确认', 2: '驳回', 3: '银行退回' }
        return map[val] ?? '未知状态'
      },
    },
    methods: {
      // 点击查询按钮触发的事件
      handleQuery(row) {
        this.$emit('handleQuery', row?.receiptNumber ? row : {})
      },
      handleDetail({ id }) {
        const routeData = this.$router.resolve({
          path: `/finance-center/ticket-management/ticket-management-details`,
          query: { id },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>
<style scoped lang="scss">
  .query-dialog {
    .el-dialog__body {
      padding: 20px 30px !important;
    }
    .el-dialog__footer {
      .dialog-footer {
        padding: 10px 100px !important;
        display: flex !important;
        justify-content: center !important;
      }
    }
  }
</style>
