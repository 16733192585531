<template>
  <el-dialog
    title="收款登记"
    width="800px"
    class="dialog"
    v-if="payeeVisible"
    :visible.sync="payeeVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div>
      <h4>
        <!-- <span class="red">*&nbsp;</span> -->
        收款凭证
      </h4>
      <fileUpload
        class="mt15 mb15"
        :uploadParams="{
          amount:10,
          size:999999999
        }"
        tipText="最多上传10张，支持格式：PDF、Word、Excel、Txt、JPG、PNG、BMP、GIF、RAR、ZIP，仅用于收款登记，实际收款金额以财务到账为准"
        @uploadList="changeUploadList"
        ref="fileUploadRef"
        :showInfoText="false"
      ></fileUpload> 
    </div>
    <!-- <div class="img-wrap mt20" v-else>
      <img
        :src="item"
        v-for="(item, i) in receiptRegistrationUrl.split(',')"
        :key="i"
        @click="handlePreview(receiptRegistrationUrl.split(','), i)"
      />
    </div> -->

    <div class="text-center mt20">
      <el-button type="primary" @click="save">
        确定
      </el-button>
      <el-button @click="close">取消</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import fileUpload from "@/views/finance-center/ticket-management/components/file-upload"
import { uploadReceiptRegistration } from '@/api/order'
import { getFileSuffix } from 'kits'
export default {
  data() {
    return {
      orderId: '',
      orderCode: '',
      receiptRegistrationUrl: '',
      payeeVisible: false,
      showViewer: false, //图片预览参数
      imgUrl: [], //图片预览url
    }
  },
  components:{
    fileUpload
  },
  methods: {
    // 上传 rendingsUrl
    changeUploadList(list) {
      let _urls = list.map(item => {
        let _name = `${item.name}`?.replace(/,/g, '');
        let path = item.path;
        if(!path.includes('?name=')){
          if(path.includes('?')){
            path = `${path}&name=${_name}`
          }else{
            path = `${path}?name=${_name}`
          }
        }
        return path
      })
      this.receiptRegistrationUrl = _urls.join(',')
    },

    // 确定
    save() {
        uploadReceiptRegistration({
          orderId: this.orderId,
          receiptRegistrationUrl: this.receiptRegistrationUrl,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.close()
            this.$message.success('保存成功')
            this.$parent.getDetail()
          }
        })
      // }
    },

    // 大图预览
    handlePreview(item, i) {
      if (Array.isArray(item)) {
        this.imgUrl = [item[i]]
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    closeViewer() {
      this.showViewer = false
    },

    // 关闭弹窗重置表单
    close() {
      this.orderId = ''
      this.payeeVisible = false
    },
    // 获取文件名字
    getFileNameFromUrl(url) {
      const nameParams = url.split('?name=');
      const nameParamStr = nameParams.length > 1 ? nameParams[nameParams.length - 1] : '';
      return nameParamStr ? nameParamStr : this.getFileNameFromUrlFile(url);
    },
    // 获取不到文件名称去文件路径的后缀作为文件名
    getFileNameFromUrlFile(url) {
      const pathname = new URL(url).pathname;
      const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
      return fileName;
    },
    // 回显附件
    initFileUpload(){
      this.$nextTick(()=>{
        let data = this.receiptRegistrationUrl.split(',').map(item=>{
          let name = this.getFileNameFromUrl(item)
          return {
            path:item,
            name,
            suffix:getFileSuffix(name)
          }
        })
        this.$refs.fileUploadRef.initUpload(data)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  h4 {
    margin: 10px 0 0;
  }
  .img-wrap {
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin-right: 20px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
::v-deep {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
